<!-- 补全产品信息 -->
<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('warehouse.product_info')"
    width="auto"
    :centered="true"
    :mask-closable="false"
    :closable="false"
    :confirm-loading="confirmLoading"
    :ok-text="$t('warehouse.confirm_and_create_transportation_plan')"
    @ok="handleConfirmAndCreate"
  >
    <a-table
      style="overflow-x: auto;"
      size="small"
      :columns="columns"
      :data-source="boxList"
      :pagination="pagination"
      :row-key="(record) => record.detailId"
      :expanded-row-keys="boxExpandedRowKeysList"
      :expandIconAsCell="false"
      :expandIconColumnIndex="2"
      @change="(page) => pagination.current = page.current"
    >
      <template #serialNumber="{ index }">
        {{ index + 1 }}
      </template>
      <template #selfBoxNo="{ record }">
        {{ record.boxNo }}
      </template>
      <template #expandIcon="{ expanded, record }">
        <CHaveProductInformation :open="expanded" @click="handleOpenOrCloseRow(record)" />
      </template>
      <template #encasementSize="{ record }">
        {{ record.containerLength }}x{{ record.containerWidth }}x{{ record.containerHeight }}cm
      </template>
      <template #encasementWeight="{ record }">
        {{ gToKg(record.containerWeight) }}kg
      </template>
      <template #containerCount="{ record }">
        {{ getBoxProductCount(record) }} Unit
      </template>
      <template #operation="{ record }">
        <a-row :gutter="[8, 8]">
          <a-col>
            <a-button type="link" size="small" @click="handleOpenProductModal(record)">{{ $t('warehouse.add_product') }}</a-button>
          </a-col>
        </a-row>
      </template>
      <template #expandedRowRender="{ record }">
        <a-table
          :columns="innerColumns"
          :data-source="record.items"
          :pagination="false"
          :row-key="(record) => record.productId"
          size="small"
        >
          <template #productInfo="{ record }">
            <a-row :gutter="8" type="flex" align="middle">
              <a-col>
                <div class="table-list-img-common">
                  <c-image
                    :src="record.productImgUrl"
                    :thumbWidth="600"
                    :thumbHeight="600"
                  />
                </div>
              </a-col>
              <a-col flex="1">
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </a-col>
            </a-row>
          </template>
          <template #containerCount="{ record }">
            <a-input-number
              v-model:value="record.containerCount"
              :min="1"
              :max="9999"
              :precision="0"
            ></a-input-number> Unit
          </template>
          <template #operation="{ record, index }">
            <a-button type="link" danger @click="handleRemoveBoxProduct(record.parentRowKey, index)">{{ $t('common.remove') }}</a-button>
          </template>
        </a-table>
      </template>
    </a-table>
    <ProductListModal ref="productListModalRef" @confirm="handleSetProduct" />
  </a-modal>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, watch } from 'vue';
import { Button, Col, InputNumber, Modal, Row, Table, message } from 'ant-design-vue';
import CPager from '@/views/components/CPager.vue';
import CImage from '@/views/components/CImage.vue';
import CHaveProductInformation from '@/views/components/CHaveProductInformation.vue';
import ProductListModal from './ProductListModal.vue';
import { useI18n } from "vue-i18n/index";
import { gToKg } from '@/utils/general.js';
import { createTransportPlanByNeedProduct } from '@/api/modules/transportation/index.js';
import { warehouseType as warehouseTypeEnum } from '@/enum/enum.json';

export default defineComponent({
  components: {
    AModal: Modal,
    ATable: Table,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    AInputNumber: InputNumber,
    CPager,
    CImage,
    CHaveProductInformation,
    ProductListModal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    warehouseType: {
      type: Number,
      default: warehouseTypeEnum.storageWarehouse,
    },
    planId: {
      type: String,
    },
    items: {
      type: Array,
      default: () => ([])
    }
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const productListModalRef = ref(null);

    const state = reactive({
      visible: false,
      confirmLoading: false,
      boxList: [],
      boxExpandedRowKeysList: [],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        hideOnSinglePage: true,
        showSizeChanger: false,
        size: 'small',
        position: 'both',
      },
    })

    const columns = [
      {
        width: 80,
        title: () => vueI18n.t("warehouse.serial_number"),
        slots: {
          customRender: "serialNumber"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("common.user_defined_box_no"),
        slots: {
          customRender: "selfBoxNo"
        }
      },
      { width: 50, },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.encasement_size"),
        slots: {
          customRender: "encasementSize"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.encasement_weight"),
        slots: {
          customRender: "encasementWeight"
        }
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "containerCount"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("common.operation"),
        slots: {
          customRender: "operation"
        }
      },
    ]

    const innerColumns = [
      { width: 60, },
      {
        width: 350,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
       {
        title: 'SESKU',
        dataIndex: "seSku",
        width: 150,
      },
      {
        width: 200,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "containerCount"
        }
      },
      {
        slots: {
          customRender: "operation"
        }
      },
    ];

    const getBoxProductCount = (record) => {
      if (!record.items) {
        return 0;
      }
      return record.items.reduce((total, item) => {
        if (item.containerCount && item.containerCount > 0) {
          return total += item.containerCount;
        }
      }, 0);
    };

    // 设置全部行展开
    const handleSetAllExpandedRows = () => {
      state.boxExpandedRowKeysList = state.boxList.map(item => item.detailId);
    }

    // 展开关闭行
    const handleOpenOrCloseRow = (record, keepOpen = false) => {
      let rowKey = record.detailId;
      if (state.boxExpandedRowKeysList.includes(rowKey)) {
        if (!keepOpen) {
          state.boxExpandedRowKeysList = state.boxExpandedRowKeysList.filter(item => item !== rowKey);
        }
      } else {
        state.boxExpandedRowKeysList.push(rowKey);
      }
    }

    const handleSetProduct = ({ list, record }) => {
      let item = state.boxList.find(item => item.detailId === record.detailId);
      item.items = list.map(item => {
        if (!('containerCount' in item)) {
          item.containerCount = null;
        }
        item.productId = item.id;
        item.productImgUrl = item.imgUrl;
        item.parentRowKey = record.detailId;
        return item;
      });
      handleOpenOrCloseRow(record, true);
    }

    const handleOpenProductModal = (record) => {
      productListModalRef.value.open(record, false);
    }

    const handleRemoveBoxProduct = (parentRowKey, index) => {
      let boxItem = state.boxList.find(item => item.detailId === parentRowKey);
      if (boxItem) {
        boxItem.items.splice(index, 1);
      }
    }

    const handleValidate = () => {
      if (!state.boxList.length) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.box_list')]));
        return false;
      }

      let list = state.boxList.map(item => {
        let items = item.items.map(ite => ({
          productId: ite.id,
          containerCount: ite.containerCount,
        }));

        return {
          detailId: item.detailId,
          items,
        }
      });

      let result = true;
      let msg = '';
      for (let i = 0; i < list.length; i++) {
        const item = list[i];

        if (!item.items.length) {
          result = false;
          msg = vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.product_info')]);
          break;
        }
        if (item.items.length) {
          for (let j = 0; j < item.items.length; j++) {
            const product = item.items[j];
            if (!product.containerCount) {
              result = false;
              msg = vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.product_quantity')]);
              break;
            }
          }
        }
      }

      if (!result) {
        message.error(msg);
        return false;
      }

      return list;
    }

    const handleConfirmAndCreate = () => {
      let items = handleValidate();
      if (!items) {
        return;
      }
      const data = {
        planId: props.planId,
        warehouseType: props.warehouseType,
        items,
      }
      state.confirmLoading = true;
      createTransportPlanByNeedProduct(data).then(({ result }) => {
        message.success(vueI18n.t('common.succeed'));
        emit('refresh', result);
        close();
      }).catch(() => {}).finally(() => {
        state.confirmLoading = false;
      });
    }

    const close = () => {
      state.visible = false;
      emit('visible', false);
    }

    watch(() => [props.visible, props.items], ([visible, items]) => {
      if (typeof visible === 'boolean') {
        state.visible = props.visible;
      }
      if (Array.isArray(items)) {
        state.boxList = items;
        handleSetAllExpandedRows();
      }
    }, { immediate: true, deep: true });

    return {
      ...toRefs(state),
      gToKg,
      productListModalRef,
      columns,
      innerColumns,
      getBoxProductCount,
      handleOpenOrCloseRow,
      handleSetProduct,
      handleOpenProductModal,
      handleRemoveBoxProduct,
      handleConfirmAndCreate,
    }
  }
})
</script>

<style scoped>

</style>